import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';



Vue.use(Vuetify);

Vue.prototype.url = window.location.hostname.includes('templink') || window.location.hostname.includes('localhost') ? 'tl' : window.location.hostname.includes('fcmi') ? 'fcmi' : 'tl'
Vue.prototype.siteColor = Vue.prototype.url === 'tl' ? '#0095DA' : '#FA712A'

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: Vue.prototype.siteColor
      },
    }
  }
});