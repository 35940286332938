import * as queries from "@/graphql/queries";
import * as allMutations from "@/graphql/mutations";
import * as subscriptions from "@/graphql/subscriptions";
import { getAllLocations } from "@/store/custom"
import {
    API
} from "aws-amplify"
import { createLocation } from "../../graphql/mutations";

const state = {
    locations: []
}

const getters = {
    locationNames(state) {
        return state.locations.map(item => item.name)
    }
}

const actions = {
    async allLocations({
        commit,
        dispatch
    }) {
        const {
            data: {
                listLocations: {
                    items
                }
            }
        } = await API.graphql({
            query: queries.listLocations,
            variables: {
                limit: 1000
            }
        })
        commit("setLocations", items.sort((a,b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
    },
    async updateLocation({
        
    }, { id, store }) {
        const {
            data: {
                updateLocation
            }
        } = await API.graphql({
            query: allMutations.updateLocation,
            variables: {
                input: {
                    id,
                    locationStoreId: store
                }
            }
        })
        if (updateLocation.id) return true
        else return false
    },
    async updateSensor({
        commit,
        dispatch
    }, sensor) {
        const {
            data: {
                updateSensor
            }
        } = await API.graphql({
            query: allMutations.updateSensor,
            variables: {
                input: {
                    id: sensor.id,
                    sensorLocationId: sensor.location
                }
            }
        })
        if (updateSensor.id) return true
        else return false
    },
    async filterLocations({
        rootGetters
    }, locations) {
        let allLocations = locations.map(item => {
            // if (item.distributors.items.length) {
            //     console.log(item.distributors.items)
            //     item.distributors = item.distributors.items[0].distributor.name
            // } else item.distributors = "NA"
            if (!item.owner) {
                item.owner = "NA"
                item.distributors = "NA"
            } else {
                item.owner = item.owner.name
                item.distributors = "NA"
                // item.distributors = item.owner.distributors.items[0].
            }
            return item
        })
        if (rootGetters["user/isDistributor"]) {
            return allLocations.filter(item => item.distributors === rootGetters["user/userDistributorName"])
        } else return allLocations
    },
    async addLocation({
        commit
    }, name) {
        const {
            data: {
                createLocation
            }
        } = await API.graphql({
            query: allMutations.createLocation,
            variables: {
                input: {
                    name
                }
            }
        })
        commit("pushLocation", createLocation)
    },
    async removeLocation({
        commit
    }, id) {
        const {
            data: {
                deleteLocation
            }
        } = await API.graphql({
            query: allMutations.deleteLocation,
            variables: {
                input: {
                    id
                }
            }
        })
        commit("removeSingleLocation", deleteLocation.id)
    },
}

const mutations = {
    setLocations(state, locations) {
        state.locations = locations
    },
    pushLocation(state, location) {
        state.locations.push(location)
    },
    removeSingleLocation(state, locationId) {
        let index = state.locations.findIndex(item => item.id === locationId)
        state.locations.splice(index, 1)
    }
}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}