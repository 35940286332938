/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updatedSensor = /* GraphQL */ `
  subscription UpdatedSensor($id: ID!) {
    updatedSensor(id: $id) {
      id
      uuid
      label
      name
      duel
      duelId
      serialNumber
      oem
      harnessNumber
      partNumber
      development
      downlink
      test
      version
      online
      currentTemp
      currentTemp2
      currentTempFull
      certified
      t1
      t2
      t3
      t4
      v1
      v2
      v3
      p1
      p2
      p3
      p4
      p5
      p6
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      compSuctionTemp
      compReturnTemp
      ambient
      displayValues
      code
      fuelCell {
        name
        treatments {
          items {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        tunnel
        createdAt
        updatedAt
        __typename
      }
      recording
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locationNote
      sensorLocationId
      device {
        id
        model
        manufacturer
        test
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sensorDeviceId
      sensorUserId
      readings {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          error
          readingSensorId
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          minFull
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingTreatmentId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          tag {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      active
      alert
      alarm
      high
      low
      highC
      lowC
      email
      phone
      time
      raw
      duration
      monitor
      battery
      humidity
      leak
      subs {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notes
      lastCheckIn
      alarms {
        items {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notifications {
        items {
          id
          type
          info
          active
          default
          pause
          nickname
          log {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          notificationSensorId
          messages {
            nextToken
            __typename
          }
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      customer {
        id
        first
        last
        email
        phone
        approved
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        createdAt
        updatedAt
        __typename
      }
      events {
        items {
          id
          createdAt
          alarm {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          logs {
            nextToken
            __typename
          }
          messages {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          resolved
          status
          result
          notify
          notifications {
            nextToken
            __typename
          }
          notes
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reports {
        items {
          id
          createdAt
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          period
          reportSensorId
          periodStartTimestamp
          highTemps
          lowTemps
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportingEnabled
      andrewTest
      validated
      timezone
      degreePref
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateLocation = /* GraphQL */ `
  subscription OnCreateLocation {
    onCreateLocation {
      id
      name
      address
      city
      state
      zip
      code
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        createdAt
        updatedAt
        __typename
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      store {
        id
        name
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLocation = /* GraphQL */ `
  subscription OnUpdateLocation {
    onUpdateLocation {
      id
      name
      address
      city
      state
      zip
      code
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        createdAt
        updatedAt
        __typename
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      store {
        id
        name
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteLocation = /* GraphQL */ `
  subscription OnDeleteLocation {
    onDeleteLocation {
      id
      name
      address
      city
      state
      zip
      code
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        createdAt
        updatedAt
        __typename
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      store {
        id
        name
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateStore = /* GraphQL */ `
  subscription OnCreateStore {
    onCreateStore {
      id
      name
      distributor {
        id
        name
        owners {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        stores {
          items {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locations {
        items {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateStore = /* GraphQL */ `
  subscription OnUpdateStore {
    onUpdateStore {
      id
      name
      distributor {
        id
        name
        owners {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        stores {
          items {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locations {
        items {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteStore = /* GraphQL */ `
  subscription OnDeleteStore {
    onDeleteStore {
      id
      name
      distributor {
        id
        name
        owners {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        stores {
          items {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locations {
        items {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateStock = /* GraphQL */ `
  subscription OnCreateStock {
    onCreateStock {
      devEui
      name
      email
      joinEui
      appKey
      brand
      model
      firmware
      sn
      daddr
      profile
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateStock = /* GraphQL */ `
  subscription OnUpdateStock {
    onUpdateStock {
      devEui
      name
      email
      joinEui
      appKey
      brand
      model
      firmware
      sn
      daddr
      profile
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteStock = /* GraphQL */ `
  subscription OnDeleteStock {
    onDeleteStock {
      devEui
      name
      email
      joinEui
      appKey
      brand
      model
      firmware
      sn
      daddr
      profile
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      first
      last
      email
      phone
      code
      approved
      distributor {
        id
        name
        owners {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        stores {
          items {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      store {
        id
        name
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      auth
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      admin {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        createdAt
        updatedAt
        __typename
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subs {
        items {
          id
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      type
      status
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      fuelCells {
        items {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensorActive
      payment
      business
      degreePref
      resolution
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      first
      last
      email
      phone
      code
      approved
      distributor {
        id
        name
        owners {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        stores {
          items {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      store {
        id
        name
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      auth
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      admin {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        createdAt
        updatedAt
        __typename
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subs {
        items {
          id
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      type
      status
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      fuelCells {
        items {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensorActive
      payment
      business
      degreePref
      resolution
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      first
      last
      email
      phone
      code
      approved
      distributor {
        id
        name
        owners {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        stores {
          items {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      store {
        id
        name
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      auth
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      admin {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        createdAt
        updatedAt
        __typename
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subs {
        items {
          id
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      type
      status
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      fuelCells {
        items {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensorActive
      payment
      business
      degreePref
      resolution
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDistributor = /* GraphQL */ `
  subscription OnCreateDistributor {
    onCreateDistributor {
      id
      name
      owners {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      stores {
        items {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDistributor = /* GraphQL */ `
  subscription OnUpdateDistributor {
    onUpdateDistributor {
      id
      name
      owners {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      stores {
        items {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDistributor = /* GraphQL */ `
  subscription OnDeleteDistributor {
    onDeleteDistributor {
      id
      name
      owners {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      stores {
        items {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDevice = /* GraphQL */ `
  subscription OnCreateDevice {
    onCreateDevice {
      id
      model
      manufacturer
      test
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDevice = /* GraphQL */ `
  subscription OnUpdateDevice {
    onUpdateDevice {
      id
      model
      manufacturer
      test
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDevice = /* GraphQL */ `
  subscription OnDeleteDevice {
    onDeleteDevice {
      id
      model
      manufacturer
      test
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateReading = /* GraphQL */ `
  subscription OnCreateReading {
    onCreateReading {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        murphyTemps {
          items {
            id
            temp
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      error
      readingSensorId
      temp1
      temp2
      temp3
      temp4
      voltage1
      voltage2
      voltage3
      pressure1
      pressure2
      pressure3
      pressure4
      pressure5
      pressure6
      min
      minFull
      min2
      am
      b1Syrup
      b2Syrup
      b3Syrup
      b4Syrup
      b1SyrupOut
      b2SyrupOut
      b3SyrupOut
      b4SyrupOut
      b1Water
      b2Water
      b3Water
      b4Water
      b1Defrost
      b2Defrost
      b3Defrost
      b4Defrost
      b1Liquid
      b2Liquid
      b3Liquid
      b4Liquid
      b1Fill
      b2Fill
      b3Fill
      b4Fill
      b1BIB
      b2BIB
      b3BIB
      b4BIB
      b1C02
      b2C02
      b3C02
      b4C02
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      ambient
      H20Pressure
      C02Pressure
      leftHighPressureCoil
      rightHighPressureCoil
      compSuctionTemp
      compReturnTemp
      compCut
      leftCompContCoil
      rightCompContCoil
      testSignal
      testAcknowledge
      gnd1
      gnd2
      gnd3
      gnd4
      ACReturn
      ACIn
      readingTreatmentId
      updatedAt
      __typename
    }
  }
`;
export const onUpdateReading = /* GraphQL */ `
  subscription OnUpdateReading {
    onUpdateReading {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        murphyTemps {
          items {
            id
            temp
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      error
      readingSensorId
      temp1
      temp2
      temp3
      temp4
      voltage1
      voltage2
      voltage3
      pressure1
      pressure2
      pressure3
      pressure4
      pressure5
      pressure6
      min
      minFull
      min2
      am
      b1Syrup
      b2Syrup
      b3Syrup
      b4Syrup
      b1SyrupOut
      b2SyrupOut
      b3SyrupOut
      b4SyrupOut
      b1Water
      b2Water
      b3Water
      b4Water
      b1Defrost
      b2Defrost
      b3Defrost
      b4Defrost
      b1Liquid
      b2Liquid
      b3Liquid
      b4Liquid
      b1Fill
      b2Fill
      b3Fill
      b4Fill
      b1BIB
      b2BIB
      b3BIB
      b4BIB
      b1C02
      b2C02
      b3C02
      b4C02
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      ambient
      H20Pressure
      C02Pressure
      leftHighPressureCoil
      rightHighPressureCoil
      compSuctionTemp
      compReturnTemp
      compCut
      leftCompContCoil
      rightCompContCoil
      testSignal
      testAcknowledge
      gnd1
      gnd2
      gnd3
      gnd4
      ACReturn
      ACIn
      readingTreatmentId
      updatedAt
      __typename
    }
  }
`;
export const onDeleteReading = /* GraphQL */ `
  subscription OnDeleteReading {
    onDeleteReading {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        murphyTemps {
          items {
            id
            temp
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      error
      readingSensorId
      temp1
      temp2
      temp3
      temp4
      voltage1
      voltage2
      voltage3
      pressure1
      pressure2
      pressure3
      pressure4
      pressure5
      pressure6
      min
      minFull
      min2
      am
      b1Syrup
      b2Syrup
      b3Syrup
      b4Syrup
      b1SyrupOut
      b2SyrupOut
      b3SyrupOut
      b4SyrupOut
      b1Water
      b2Water
      b3Water
      b4Water
      b1Defrost
      b2Defrost
      b3Defrost
      b4Defrost
      b1Liquid
      b2Liquid
      b3Liquid
      b4Liquid
      b1Fill
      b2Fill
      b3Fill
      b4Fill
      b1BIB
      b2BIB
      b3BIB
      b4BIB
      b1C02
      b2C02
      b3C02
      b4C02
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      ambient
      H20Pressure
      C02Pressure
      leftHighPressureCoil
      rightHighPressureCoil
      compSuctionTemp
      compReturnTemp
      compCut
      leftCompContCoil
      rightCompContCoil
      testSignal
      testAcknowledge
      gnd1
      gnd2
      gnd3
      gnd4
      ACReturn
      ACIn
      readingTreatmentId
      updatedAt
      __typename
    }
  }
`;
export const onCreateFuelCell = /* GraphQL */ `
  subscription OnCreateFuelCell {
    onCreateFuelCell {
      name
      treatments {
        items {
          id
          tag
          active
          start
          end
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          murphyTemps {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      online
      lastUpdate
      fuel
      water
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        createdAt
        updatedAt
        __typename
      }
      tunnel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFuelCell = /* GraphQL */ `
  subscription OnUpdateFuelCell {
    onUpdateFuelCell {
      name
      treatments {
        items {
          id
          tag
          active
          start
          end
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          murphyTemps {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      online
      lastUpdate
      fuel
      water
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        createdAt
        updatedAt
        __typename
      }
      tunnel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFuelCell = /* GraphQL */ `
  subscription OnDeleteFuelCell {
    onDeleteFuelCell {
      name
      treatments {
        items {
          id
          tag
          active
          start
          end
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          murphyTemps {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      online
      lastUpdate
      fuel
      water
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        createdAt
        updatedAt
        __typename
      }
      tunnel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTreatment = /* GraphQL */ `
  subscription OnCreateTreatment {
    onCreateTreatment {
      id
      tag
      active
      start
      end
      fuelCell {
        name
        treatments {
          items {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        tunnel
        createdAt
        updatedAt
        __typename
      }
      readings {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          error
          readingSensorId
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          minFull
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingTreatmentId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      murphyTemps {
        items {
          id
          temp
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTreatment = /* GraphQL */ `
  subscription OnUpdateTreatment {
    onUpdateTreatment {
      id
      tag
      active
      start
      end
      fuelCell {
        name
        treatments {
          items {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        tunnel
        createdAt
        updatedAt
        __typename
      }
      readings {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          error
          readingSensorId
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          minFull
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingTreatmentId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      murphyTemps {
        items {
          id
          temp
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTreatment = /* GraphQL */ `
  subscription OnDeleteTreatment {
    onDeleteTreatment {
      id
      tag
      active
      start
      end
      fuelCell {
        name
        treatments {
          items {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        tunnel
        createdAt
        updatedAt
        __typename
      }
      readings {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          error
          readingSensorId
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          minFull
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingTreatmentId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      murphyTemps {
        items {
          id
          temp
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTemp = /* GraphQL */ `
  subscription OnCreateTemp {
    onCreateTemp {
      id
      temp
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        murphyTemps {
          items {
            id
            temp
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTemp = /* GraphQL */ `
  subscription OnUpdateTemp {
    onUpdateTemp {
      id
      temp
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        murphyTemps {
          items {
            id
            temp
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTemp = /* GraphQL */ `
  subscription OnDeleteTemp {
    onDeleteTemp {
      id
      temp
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        murphyTemps {
          items {
            id
            temp
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSensor = /* GraphQL */ `
  subscription OnCreateSensor {
    onCreateSensor {
      id
      uuid
      label
      name
      duel
      duelId
      serialNumber
      oem
      harnessNumber
      partNumber
      development
      downlink
      test
      version
      online
      currentTemp
      currentTemp2
      currentTempFull
      certified
      t1
      t2
      t3
      t4
      v1
      v2
      v3
      p1
      p2
      p3
      p4
      p5
      p6
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      compSuctionTemp
      compReturnTemp
      ambient
      displayValues
      code
      fuelCell {
        name
        treatments {
          items {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        tunnel
        createdAt
        updatedAt
        __typename
      }
      recording
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locationNote
      sensorLocationId
      device {
        id
        model
        manufacturer
        test
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sensorDeviceId
      sensorUserId
      readings {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          error
          readingSensorId
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          minFull
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingTreatmentId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          tag {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      active
      alert
      alarm
      high
      low
      highC
      lowC
      email
      phone
      time
      raw
      duration
      monitor
      battery
      humidity
      leak
      subs {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notes
      lastCheckIn
      alarms {
        items {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notifications {
        items {
          id
          type
          info
          active
          default
          pause
          nickname
          log {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          notificationSensorId
          messages {
            nextToken
            __typename
          }
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      customer {
        id
        first
        last
        email
        phone
        approved
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        createdAt
        updatedAt
        __typename
      }
      events {
        items {
          id
          createdAt
          alarm {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          logs {
            nextToken
            __typename
          }
          messages {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          resolved
          status
          result
          notify
          notifications {
            nextToken
            __typename
          }
          notes
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reports {
        items {
          id
          createdAt
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          period
          reportSensorId
          periodStartTimestamp
          highTemps
          lowTemps
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportingEnabled
      andrewTest
      validated
      timezone
      degreePref
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSensor = /* GraphQL */ `
  subscription OnUpdateSensor {
    onUpdateSensor {
      id
      uuid
      label
      name
      duel
      duelId
      serialNumber
      oem
      harnessNumber
      partNumber
      development
      downlink
      test
      version
      online
      currentTemp
      currentTemp2
      currentTempFull
      certified
      t1
      t2
      t3
      t4
      v1
      v2
      v3
      p1
      p2
      p3
      p4
      p5
      p6
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      compSuctionTemp
      compReturnTemp
      ambient
      displayValues
      code
      fuelCell {
        name
        treatments {
          items {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        tunnel
        createdAt
        updatedAt
        __typename
      }
      recording
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locationNote
      sensorLocationId
      device {
        id
        model
        manufacturer
        test
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sensorDeviceId
      sensorUserId
      readings {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          error
          readingSensorId
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          minFull
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingTreatmentId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          tag {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      active
      alert
      alarm
      high
      low
      highC
      lowC
      email
      phone
      time
      raw
      duration
      monitor
      battery
      humidity
      leak
      subs {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notes
      lastCheckIn
      alarms {
        items {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notifications {
        items {
          id
          type
          info
          active
          default
          pause
          nickname
          log {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          notificationSensorId
          messages {
            nextToken
            __typename
          }
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      customer {
        id
        first
        last
        email
        phone
        approved
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        createdAt
        updatedAt
        __typename
      }
      events {
        items {
          id
          createdAt
          alarm {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          logs {
            nextToken
            __typename
          }
          messages {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          resolved
          status
          result
          notify
          notifications {
            nextToken
            __typename
          }
          notes
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reports {
        items {
          id
          createdAt
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          period
          reportSensorId
          periodStartTimestamp
          highTemps
          lowTemps
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportingEnabled
      andrewTest
      validated
      timezone
      degreePref
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSensor = /* GraphQL */ `
  subscription OnDeleteSensor {
    onDeleteSensor {
      id
      uuid
      label
      name
      duel
      duelId
      serialNumber
      oem
      harnessNumber
      partNumber
      development
      downlink
      test
      version
      online
      currentTemp
      currentTemp2
      currentTempFull
      certified
      t1
      t2
      t3
      t4
      v1
      v2
      v3
      p1
      p2
      p3
      p4
      p5
      p6
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      compSuctionTemp
      compReturnTemp
      ambient
      displayValues
      code
      fuelCell {
        name
        treatments {
          items {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        tunnel
        createdAt
        updatedAt
        __typename
      }
      recording
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locationNote
      sensorLocationId
      device {
        id
        model
        manufacturer
        test
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sensorDeviceId
      sensorUserId
      readings {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          error
          readingSensorId
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          minFull
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingTreatmentId
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          tag {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      active
      alert
      alarm
      high
      low
      highC
      lowC
      email
      phone
      time
      raw
      duration
      monitor
      battery
      humidity
      leak
      subs {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notes
      lastCheckIn
      alarms {
        items {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notifications {
        items {
          id
          type
          info
          active
          default
          pause
          nickname
          log {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          notificationSensorId
          messages {
            nextToken
            __typename
          }
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      customer {
        id
        first
        last
        email
        phone
        approved
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        createdAt
        updatedAt
        __typename
      }
      events {
        items {
          id
          createdAt
          alarm {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          logs {
            nextToken
            __typename
          }
          messages {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          resolved
          status
          result
          notify
          notifications {
            nextToken
            __typename
          }
          notes
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reports {
        items {
          id
          createdAt
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          period
          reportSensorId
          periodStartTimestamp
          highTemps
          lowTemps
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportingEnabled
      andrewTest
      validated
      timezone
      degreePref
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent {
    onCreateEvent {
      id
      createdAt
      alarm {
        id
        input
        value
        valueC
        predicate
        duration
        active
        ack
        pause
        default
        start
        stop
        type
        timezone
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        alarmSensorId
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          alert {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          responseBy
          userResponse
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      resolved
      status
      result
      notify
      notifications {
        items {
          id
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notes
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent {
    onUpdateEvent {
      id
      createdAt
      alarm {
        id
        input
        value
        valueC
        predicate
        duration
        active
        ack
        pause
        default
        start
        stop
        type
        timezone
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        alarmSensorId
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          alert {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          responseBy
          userResponse
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      resolved
      status
      result
      notify
      notifications {
        items {
          id
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notes
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent {
    onDeleteEvent {
      id
      createdAt
      alarm {
        id
        input
        value
        valueC
        predicate
        duration
        active
        ack
        pause
        default
        start
        stop
        type
        timezone
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        alarmSensorId
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          alert {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          responseBy
          userResponse
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      resolved
      status
      result
      notify
      notifications {
        items {
          id
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notes
      updatedAt
      __typename
    }
  }
`;
export const onCreateLog = /* GraphQL */ `
  subscription OnCreateLog {
    onCreateLog {
      id
      createdAt
      alarm
      alerts
      logSensorId
      type
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      trigger
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      notification {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLog = /* GraphQL */ `
  subscription OnUpdateLog {
    onUpdateLog {
      id
      createdAt
      alarm
      alerts
      logSensorId
      type
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      trigger
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      notification {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const onDeleteLog = /* GraphQL */ `
  subscription OnDeleteLog {
    onDeleteLog {
      id
      createdAt
      alarm
      alerts
      logSensorId
      type
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      trigger
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      notification {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer {
    onCreateCustomer {
      id
      first
      last
      email
      phone
      approved
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer {
    onUpdateCustomer {
      id
      first
      last
      email
      phone
      approved
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer {
    onDeleteCustomer {
      id
      first
      last
      email
      phone
      approved
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAlarm = /* GraphQL */ `
  subscription OnCreateAlarm {
    onCreateAlarm {
      id
      input
      value
      valueC
      predicate
      duration
      active
      ack
      pause
      default
      start
      stop
      type
      timezone
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      alarmSensorId
      events {
        items {
          id
          createdAt
          alarm {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          logs {
            nextToken
            __typename
          }
          messages {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          resolved
          status
          result
          notify
          notifications {
            nextToken
            __typename
          }
          notes
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAlarm = /* GraphQL */ `
  subscription OnUpdateAlarm {
    onUpdateAlarm {
      id
      input
      value
      valueC
      predicate
      duration
      active
      ack
      pause
      default
      start
      stop
      type
      timezone
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      alarmSensorId
      events {
        items {
          id
          createdAt
          alarm {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          logs {
            nextToken
            __typename
          }
          messages {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          resolved
          status
          result
          notify
          notifications {
            nextToken
            __typename
          }
          notes
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAlarm = /* GraphQL */ `
  subscription OnDeleteAlarm {
    onDeleteAlarm {
      id
      input
      value
      valueC
      predicate
      duration
      active
      ack
      pause
      default
      start
      stop
      type
      timezone
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      alarmSensorId
      events {
        items {
          id
          createdAt
          alarm {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          logs {
            nextToken
            __typename
          }
          messages {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          resolved
          status
          result
          notify
          notifications {
            nextToken
            __typename
          }
          notes
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCanBus = /* GraphQL */ `
  subscription OnCreateCanBus {
    onCreateCanBus {
      id
      code
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCanBus = /* GraphQL */ `
  subscription OnUpdateCanBus {
    onUpdateCanBus {
      id
      code
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCanBus = /* GraphQL */ `
  subscription OnDeleteCanBus {
    onDeleteCanBus {
      id
      code
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      type
      info
      active
      default
      pause
      nickname
      log {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      notificationSensorId
      messages {
        items {
          id
          alert {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          responseBy
          userResponse
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      type
      info
      active
      default
      pause
      nickname
      log {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      notificationSensorId
      messages {
        items {
          id
          alert {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          responseBy
          userResponse
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      type
      info
      active
      default
      pause
      nickname
      log {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      notificationSensorId
      messages {
        items {
          id
          alert {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          responseBy
          userResponse
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateEventNotification = /* GraphQL */ `
  subscription OnCreateEventNotification {
    onCreateEventNotification {
      id
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      notification {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEventNotification = /* GraphQL */ `
  subscription OnUpdateEventNotification {
    onUpdateEventNotification {
      id
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      notification {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEventNotification = /* GraphQL */ `
  subscription OnDeleteEventNotification {
    onDeleteEventNotification {
      id
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      notification {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      alert {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      responseBy
      userResponse
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      alert {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      responseBy
      userResponse
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      alert {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      responseBy
      userResponse
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCode = /* GraphQL */ `
  subscription OnCreateCode {
    onCreateCode {
      code
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCode = /* GraphQL */ `
  subscription OnUpdateCode {
    onUpdateCode {
      code
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCode = /* GraphQL */ `
  subscription OnDeleteCode {
    onDeleteCode {
      code
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTag = /* GraphQL */ `
  subscription OnCreateTag {
    onCreateTag {
      id
      name
      sensors {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          tag {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTag = /* GraphQL */ `
  subscription OnUpdateTag {
    onUpdateTag {
      id
      name
      sensors {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          tag {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTag = /* GraphQL */ `
  subscription OnDeleteTag {
    onDeleteTag {
      id
      name
      sensors {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          tag {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSensorTag = /* GraphQL */ `
  subscription OnCreateSensorTag {
    onCreateSensorTag {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        name
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSensorTag = /* GraphQL */ `
  subscription OnUpdateSensorTag {
    onUpdateSensorTag {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        name
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSensorTag = /* GraphQL */ `
  subscription OnDeleteSensorTag {
    onDeleteSensorTag {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        name
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSub = /* GraphQL */ `
  subscription OnCreateSub {
    onCreateSub {
      id
      topic
      deviceId
      users {
        items {
          id
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensors {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSub = /* GraphQL */ `
  subscription OnUpdateSub {
    onUpdateSub {
      id
      topic
      deviceId
      users {
        items {
          id
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensors {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSub = /* GraphQL */ `
  subscription OnDeleteSub {
    onDeleteSub {
      id
      topic
      deviceId
      users {
        items {
          id
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensors {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserSub = /* GraphQL */ `
  subscription OnCreateUserSub {
    onCreateUserSub {
      id
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        createdAt
        updatedAt
        __typename
      }
      sub {
        id
        topic
        deviceId
        users {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserSub = /* GraphQL */ `
  subscription OnUpdateUserSub {
    onUpdateUserSub {
      id
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        createdAt
        updatedAt
        __typename
      }
      sub {
        id
        topic
        deviceId
        users {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserSub = /* GraphQL */ `
  subscription OnDeleteUserSub {
    onDeleteUserSub {
      id
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        createdAt
        updatedAt
        __typename
      }
      sub {
        id
        topic
        deviceId
        users {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSensorSub = /* GraphQL */ `
  subscription OnCreateSensorSub {
    onCreateSensorSub {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      sub {
        id
        topic
        deviceId
        users {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSensorSub = /* GraphQL */ `
  subscription OnUpdateSensorSub {
    onUpdateSensorSub {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      sub {
        id
        topic
        deviceId
        users {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSensorSub = /* GraphQL */ `
  subscription OnDeleteSensorSub {
    onDeleteSensorSub {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      sub {
        id
        topic
        deviceId
        users {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateReport = /* GraphQL */ `
  subscription OnCreateReport {
    onCreateReport {
      id
      createdAt
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      period
      reportSensorId
      periodStartTimestamp
      highTemps
      lowTemps
      updatedAt
      __typename
    }
  }
`;
export const onUpdateReport = /* GraphQL */ `
  subscription OnUpdateReport {
    onUpdateReport {
      id
      createdAt
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      period
      reportSensorId
      periodStartTimestamp
      highTemps
      lowTemps
      updatedAt
      __typename
    }
  }
`;
export const onDeleteReport = /* GraphQL */ `
  subscription OnDeleteReport {
    onDeleteReport {
      id
      createdAt
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          locations {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        createdAt
        updatedAt
        __typename
      }
      period
      reportSensorId
      periodStartTimestamp
      highTemps
      lowTemps
      updatedAt
      __typename
    }
  }
`;
