import * as queries from "@/graphql/queries";
import * as allMutations from "@/graphql/mutations";
import * as subscriptions from "@/graphql/subscriptions";
import {} from "@/store/custom"
import {
    API
} from "aws-amplify"

const state = {
    stores: []
}

const getters = {}

const actions = {
    async allStores({
        commit,
        dispatch
    }) {
        const {
            data: {
                listStores: {
                    items
                }
            }
        } = await API.graphql({
            query: queries.listStores,
            variables: {
                limit: 1000
            }
        })
        commit("setStores", items.sort((a,b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
    },
    async addStore({
        commit
    }, name) {
        const {
            data: {
                createStore
            }
        } = await API.graphql({
            query: allMutations.createStore,
            variables: {
                input: {
                    name
                }
            }
        })
        commit("pushStore", createStore)
    },
    async removeStore({
        commit
    }, id) {
        const {
            data: {
                deleteStore
            }
        } = await API.graphql({
            query: allMutations.deleteStore,
            variables: {
                input: {
                    id
                }
            }
        })
        commit("removeSingleStore", deleteStore.id)
    },
}

const mutations = {
    setStores(state, stores) {
        state.stores = stores
    },
    pushStore(state, store) {
        state.stores.push(store)
    },
    removeSingleStore(state, storeId) {
        let index = state.stores.findIndex(item => item.id === storeId)
        state.stores.splice(index, 1)
    }
}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}