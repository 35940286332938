/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://c5rzcmih2fezfdj3xwwljyb4ha.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-5wcd66byvrcmfaoeynerpvqsym",
    "aws_cloud_logic_custom": [
        {
            "name": "bwrDigiEndpoint",
            "endpoint": "https://u75dv7wzs9.execute-api.us-east-1.amazonaws.com/tlprod",
            "region": "us-east-1"
        },
        {
            "name": "bwrEndpoint",
            "endpoint": "https://i79flneike.execute-api.us-east-1.amazonaws.com/tlprod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:fc7e645f-70ad-4043-a909-228c58d15d50",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_9IrvsrEZ2",
    "aws_user_pools_web_client_id": "1ebk4knsts8u5q1ohbqp6fgfvo",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "bwr-dashboard-tlprod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3fl5g1fsfk0tw.cloudfront.net"
};


export default awsmobile;
